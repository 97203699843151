import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  nameEn: string;
  nameRu: string;
};

export const useLanguageTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'nameEn', header: 'Name english' },
  { accessorKey: 'nameRu', header: 'Name russian' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'language'} />,
  },
];
