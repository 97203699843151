import { createSlice } from '@reduxjs/toolkit';

type PremiumType = {
  isPremium: boolean;
};

const initialState: PremiumType = {
  isPremium: false,
};

export const premiumSlice = createSlice({
  name: 'premium',
  initialState,
  reducers: {
    changePremiumStatus: (state: PremiumType, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isPremium = action.payload;
    },
  },
});

export const { changePremiumStatus } = premiumSlice.actions;
export default premiumSlice.reducer;
