import { AxiosResponse } from 'axios';

import instance, { formDataInstance } from '../axios';
import { orderType } from '../../../types/types';

interface AllOrdersDataType {
  orders: orderType[];
  totalCount: number;
}

interface orderApiType {
  getAllOrders: (
    pageSize: number,
    currentPage: number,
    searchValue: string
  ) => Promise<AxiosResponse<AllOrdersDataType>>;
  deleteOrder: (uuid: string) => Promise<AxiosResponse<any>>;
  getOrderByUuid: (uuid: string) => Promise<AxiosResponse<{ order: orderType }>>;
  createOrder: (data: any) => Promise<AxiosResponse<{ order: orderType }>>;
  updateOrder: (uuid: string, data: any) => Promise<AxiosResponse<{ order: orderType }>>;
}

export const orderApi: orderApiType = {
  getAllOrders: async (pageSize, currentPage, searchValue) =>
    instance.get(`/v1/order/get-all`, { params: { pageSize, currentPage, searchValue } }),
  deleteOrder: async (uuid) => instance.delete(`/v1/order/${uuid}`),
  getOrderByUuid: async (uuid) => instance.get(`/v1/order/get-by-uuid/${uuid}`),
  createOrder: async (data) => instance.post('/v1/order/create', data),
  updateOrder: async (uuid, data) => formDataInstance.put(`/v1/order/update/${uuid}`, data),
};
