import React, { useState } from 'react';
import { Page } from '../index';
import { ColumnType, useBookSubcategoryTableColumns } from './useBookSubcategoryTableColumns';
import Loading from '../../components/Loading/Loading';
import { useGetBookSubcategories } from '../../hooks/ReactQuery/BookSubcategories/useGetBookSubcategories';
import useQueryParams from '../../helpers/useQueryParams';

const BookSubcategories = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetBookSubcategories(
    pageSize,
    currentPage,
    searchValue
  );

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] = data?.bookSubcategories?.map((elem: any) => ({
    id: elem?.uuid,
    order: elem?.order,
    image: elem?.image,
    nameEn: elem?.nameEn,
    nameRu: elem?.nameRu,
    isDropdown: elem?.isDropdown,
    isShown: elem?.isShown,
  }));

  return (
    <Page
      title="Subcategory book"
      description="Subcategory book info"
      columns={useBookSubcategoryTableColumns}
      formattedData={formattedData}
      searchKey="nameEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default BookSubcategories;
