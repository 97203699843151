import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import { Providers } from './redux/provider';
import { ContextProvider } from './contexts/ContextProvider';

import './index.css';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Providers>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <App />
        </ContextProvider>
      </QueryClientProvider>
    </Providers>
  </React.StrictMode>
);
