import React, { useState } from 'react';
import { Page } from '../index';
import { useUserLanguageTableColumns, ColumnType } from './useUserLanguageTableColumns';
import Loading from '../../components/Loading/Loading';
import useQueryParams from '../../helpers/useQueryParams';
import { useGetUserLanguages } from '../../hooks/ReactQuery/UserLanguage/useGetUserLanguages';

const UserLanguage = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetUserLanguages(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] = data?.userLanguages?.map((elem: any) => ({
    id: elem?.uuid,
    name: elem?.name,
    abbreviation: elem?.abbreviation,
  }));

  return (
    <Page
      title="User language"
      description="User languages info"
      columns={useUserLanguageTableColumns}
      formattedData={formattedData}
      searchKey="name"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default UserLanguage;
