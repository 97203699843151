import { AxiosResponse } from 'axios';

import instance from '../axios';
import { purchaseType } from '../../../types/types';

interface UserPurchasesDataType {
  purchases: purchaseType[];
  isPremium: boolean;
  totalCount: number;
}

interface AllPurchasesDataType {
  purchases: purchaseType[];
  totalCount: number;
}

interface purchaseApiType {
  getUserPurchases: () => Promise<AxiosResponse<UserPurchasesDataType>>;
  getAllPurchases: (
    pageSize: number,
    currentPage: number,
    searchValue: string
  ) => Promise<AxiosResponse<AllPurchasesDataType>>;
}

export const purchaseApi: purchaseApiType = {
  getUserPurchases: async () => instance.get(`/v1/purchase/get-user-purchases`),
  getAllPurchases: async (pageSize, currentPage, searchValue) =>
    instance.get(`/v1/purchase/get-all`, { params: { pageSize, currentPage, searchValue } }),
};
