import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type errorType = {
  type: string | null;
  error: any;
};

type ErrorStateType = {
  error: errorType;
};

const initialState: ErrorStateType = {
  error: {
    type: null,
    error: null,
  },
};

export const error = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state: ErrorStateType, action: PayloadAction<errorType>) => {
      // eslint-disable-next-line no-param-reassign
      state.error = action.payload;
    },
  },
});

export const { setError } = error.actions;
export default error.reducer;
