import { createSlice } from '@reduxjs/toolkit';

type SearchStateType = {
  isCourseSearchMode: boolean;
};

const initialState: SearchStateType = {
  isCourseSearchMode: false,
};

export const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    startEndSearch: (state: SearchStateType) => {
      // eslint-disable-next-line no-param-reassign
      state.isCourseSearchMode = !state.isCourseSearchMode;
    },
    endSearch: (state: SearchStateType) => {
      // eslint-disable-next-line no-param-reassign
      state.isCourseSearchMode = false;
    },
  },
});

export const { startEndSearch, endSearch } = search.actions;
export default search.reducer;
