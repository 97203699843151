export const useOrderCreatorFields = () => [
  { name: 'image', label: 'Image', type: 'file' },
  { name: 'title', label: 'Title', type: 'text' },
  { name: 'originalTitle', label: 'Original title', type: 'text' },
  { name: 'description', label: 'Description', type: 'text' },
  { name: 'chLink', label: 'Ch. link', type: 'text' },
  { name: 'originalLink', label: 'Original link', type: 'text' },
  { name: 'sbLink', label: 'SkillHunt link', type: 'text' },
  {
    name: 'status',
    label: 'Status',
    type: 'singleSelect',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'done', label: 'Done' },
      { value: 'cancel', label: 'Cancel' },
    ],
  },
];
