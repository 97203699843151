import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';
import { priceApi } from '../../api/services/price';

export type ColumnType = {
  id: string;
  titleEn: string;
  descriptionEn: string;
  packageIncludesEn: string;
  price: string;
  discount: string;
  order: string;
  isShown: string;
};

export const usePriceTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'order', header: 'Order' },
  { accessorKey: 'titleEn', header: 'Title' },
  { accessorKey: 'descriptionEn', header: 'Description' },
  { accessorKey: 'packageIncludesEn', header: 'Package includes' },
  { accessorKey: 'price', header: 'Price' },
  { accessorKey: 'discount', header: 'Discount' },
  { accessorKey: 'isShown', header: 'isShown' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'price'} />,
  },
];
