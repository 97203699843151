import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  nameEn: string;
  nameRu: string;
  isShown: string;
  isDropdown: string;
};

export const usePositionStatusTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'nameEn', header: 'Name english' },
  { accessorKey: 'nameRu', header: 'Name russian' },
  { accessorKey: 'isShown', header: 'Is shown' },
  { accessorKey: 'isDropdown', header: 'Is dropdown' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'positionStatus'} />,
  },
];
