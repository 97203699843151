import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subcategoryType } from '../../../types/types';

type SubcategoriesStateType = {
  subcategories: subcategoryType[];
};

const initialState: SubcategoriesStateType = {
  subcategories: [],
};

export const subcategorySlice = createSlice({
  name: 'subcategories',
  initialState,
  reducers: {
    setSubcategories: (state: SubcategoriesStateType, action: PayloadAction<subcategoryType[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.subcategories = action.payload;
    },
  },
});

export const { setSubcategories } = subcategorySlice.actions;
export default subcategorySlice.reducer;
