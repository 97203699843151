import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderType } from '../../../types/types';

type OrderStateType = {
  orders: orderType[];
  isOrderModalOpen: boolean;
};

const initialState: OrderStateType = {
  orders: [],
  isOrderModalOpen: false,
};

export const orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state: OrderStateType, action: PayloadAction<orderType[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.orders = action.payload;
    },
    toggleOrderModal: (state: OrderStateType, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isOrderModalOpen = action.payload;
    },
  },
});

export const { setOrders, toggleOrderModal } = orders.actions;
export default orders.reducer;
