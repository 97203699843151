import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  order: string;
  image: string;
  category: string;
  subcategory: string;
  isShown: string;
};

export const useSwiperSliderTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'order', header: 'order' },
  { accessorKey: 'image', header: 'Image' },
  { accessorKey: 'category', header: 'Related category' },
  { accessorKey: 'subcategory', header: 'Related subcategory' },
  { accessorKey: 'isShown', header: 'isShown' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'swiperSlider'} />,
  },
];
