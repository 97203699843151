import React, { useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Button } from '../../@/components/ui/button';
import { ToastNotifySuccess } from '../../helpers/toastNotify';
import FormTextField from './Fields/FormTextField';
import CheckboxField from './Fields/CheckboxField';
import NumberField from './Fields/NumberField';
import SingleSelectField from './Fields/SingleSelectField';
import MultiSelectField from './Fields/MultiSelectField';
import FileField from './Fields/FileField';
import TextareaField from './Fields/TextareaField';
import ReactDatepicker from './Fields/ReactDatepicker';
import TextEditor from './Fields/TextEditor';
import cleanHtmlContent from '../../helpers/cleanHtmlContent';
import { Heading } from '../Heading/Heading';
import { Form } from '../../@/components/ui/form';
import NumberFloatField from './Fields/NumberFloatField';

const FormCreator = ({
  title = '',
  description = '',
  fields,
  validation,
  submitFunction,
  initialData = null,
}) => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const schema = yup.object(validation).required();
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData || {
      isShown: true,
      isDropdown: true,
      isFree: false,
      isPublic: true,
      rating: 0,
      order: 0,
      like: 0,
      dislike: 0,
      originalLink: null,
      chLink: null,
    },
  });

  const onSubmit = async (submitData) => {
    setLoading(true);
    let changedData;
    if (submitData.image) {
      changedData = new FormData();

      fields.forEach((elem) => {
        if (elem.type === 'singleSelect') {
          changedData.append([elem.name], submitData[elem.name]);
        } else if (elem.type === 'multiSelect') {
          submitData?.[elem.name]?.forEach((category) => {
            changedData.append([elem.name], category.value);
          });
        } else if (elem.type === 'file') {
          if (elem.name === 'image') {
            changedData.append(
              [elem.name],
              typeof submitData.image !== 'string'
                ? submitData[elem.name]?.[0]
                : submitData[elem.name]
            );
          }

          if (elem.name === 'pdf') {
            changedData.append(
              [elem.name],
              typeof submitData.pdf !== 'string'
                ? submitData[elem.name]?.[0]
                : submitData[elem.name]
            );
          }

          if (elem.name === 'zipFile') {
            changedData.append(
              [elem.name],
              typeof submitData.zipFile !== 'string'
                ? submitData[elem.name]?.[0]
                : submitData[elem.name]
            );
          }
        } else if (elem.type === 'textEditor') {
          changedData.append([elem.name], cleanHtmlContent(submitData[elem.name]));
        } else if (elem.type === 'date') {
          changedData.append(
            submitData[elem.name] ? [elem.name] : null,
            submitData[elem.name] ? submitData[elem.name] : null
          );
        } else if (elem.name.includes('packageIncludes') && elem.type === 'text') {
          changedData.append([elem.name], submitData[elem.name].split(','));
        } else {
          changedData.append([elem.name], submitData[elem.name]);
        }
      });
    } else {
      changedData = {};
      fields.forEach((elem) => {
        if (elem.type === 'singleSelect') {
          changedData[elem.name] = submitData[elem.name];
        } else if (elem.type === 'textEditor') {
          changedData[elem.name] = cleanHtmlContent(submitData[elem.name]);
        } else if (elem.name.includes('packageIncludes') && elem.type === 'text') {
          changedData[elem.name] = submitData[elem.name].split(',');
        } else if (elem.type === 'date') {
          changedData[elem.name] = moment(submitData[elem.name]).format('DD/MM/YYYY');
        } else {
          changedData[elem.name] = submitData[elem.name];
        }
      });
    }
    try {
      const response = initialData
        ? await submitFunction(uuid, changedData)
        : await submitFunction(changedData);
      ToastNotifySuccess('success', response?.data?.message || 'Created');
      navigate(-1);
    } catch (error) {
      ToastNotifySuccess('error', error?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="nav-item bg-white dark:bg-[#42464D] pl-8 pr-8 rounded-lg h-full">
      <div className="flex justify-between items-center">
        <div className="flex gap-3 flex-col w-full">
          <div className="flex justify-between items-center">
            <Heading title={title} description={description} />
            <Button onClick={() => navigate(-1)}>
              <AiFillCloseCircle className="mr-2 h-4 w-4" />
              Close
            </Button>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col w-full">
              <div className="grid grid-cols-1 gap-8">
                {fields.map((elem) => (
                  <div key={elem.name}>
                    {elem.type === 'text' && (
                      <FormTextField elem={elem} form={form} loading={loading} />
                    )}
                    {elem.type === 'textarea' && (
                      <TextareaField elem={elem} form={form} loading={loading} />
                    )}
                    {elem.type === 'checkbox' && (
                      <CheckboxField elem={elem} form={form} loading={loading} />
                    )}
                    {elem.type === 'number' && (
                      <NumberField elem={elem} form={form} loading={loading} />
                    )}
                    {elem.type === 'float' && (
                      <NumberFloatField elem={elem} form={form} loading={loading} />
                    )}
                    {elem.type === 'singleSelect' && (
                      <SingleSelectField elem={elem} form={form} loading={loading} />
                    )}
                    {elem.type === 'multiSelect' && (
                      <MultiSelectField elem={elem} form={form} loading={loading} />
                    )}
                    {elem.type === 'file' && (
                      <FileField
                        elem={elem}
                        control={form.control}
                        errors={form?.formState?.errors}
                        setValue={form.setValue}
                        name={elem.name}
                        initialData={initialData}
                      />
                    )}
                    {elem.type === 'date' && (
                      <ReactDatepicker elem={elem} form={form} loading={loading} />
                    )}
                    {elem.type === 'textEditor' && (
                      <TextEditor elem={elem} form={form} loading={loading} />
                    )}
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-center ml-auto mr-auto mt-4 gap-4">
                <Button
                  disabled={loading}
                  type="button"
                  className="ml-auto"
                  variant="outline"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button disabled={loading} type="submit" className={`ml-auto`}>
                  {location.pathname.includes('/new') ? 'Create' : 'Update'}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default FormCreator;
