import * as yup from 'yup';

export const priceValidation = {
  titleEn: yup.string().required('Required'),
  titleRu: yup.string().required('Required'),
  descriptionEn: yup.string().required('Required'),
  descriptionRu: yup.string().required('Required'),
  buttonNameEn: yup.string().required('Required'),
  buttonNameRu: yup.string().required('Required'),
  hrefUrl: yup.string().required('Required'),
  price: yup.number().min(0).required('Required'),
  accessTime: yup.number().min(0).required('Required'),
  // price: yup.object().shape({
  //   decimal: yup
  //       .number()
  //       .test('is-decimal', 'invalid decimal', (value) => `${value}`.match(/^\d*\.{1}\d*$/)),
  // }),
  packageIncludesEn: yup.string().required('Required'),
  packageIncludesRu: yup.string().required('Required'),
  isShown: yup.boolean().required('Required'),
  order: yup.number().min(0).required('Required'),
};
