import { createSlice } from '@reduxjs/toolkit';

type MenuStateType = {
  isMenuMode: boolean;
};

const initialState: MenuStateType = {
  isMenuMode: false,
};

export const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMenu: (state: MenuStateType) => {
      // eslint-disable-next-line no-param-reassign
      state.isMenuMode = true;
    },
    closeMenu: (state: MenuStateType) => {
      // eslint-disable-next-line no-param-reassign
      state.isMenuMode = false;
    },
  },
});

export const { openMenu, closeMenu } = menu.actions;
export default menu.reducer;
