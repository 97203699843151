import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type darkModeStateType = {
  isDarkMode: boolean;
};

const initialState: darkModeStateType = {
  isDarkMode: true,
};

export const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    setDarkMode: (state: darkModeStateType, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isDarkMode = action.payload;
    },
  },
});

export const { setDarkMode } = darkModeSlice.actions;
export default darkModeSlice.reducer;
