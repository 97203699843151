import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../@/components/ui/form';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '../../../@/components/ui/select';

const SingleSelectField = ({ elem, form, loading }) => (
  <div className="mb-5">
    <div className="flex flex-col">
      <FormField
        control={form.control}
        name={elem.name}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{elem.label}</FormLabel>
            <Select disabled={loading} onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder={`Select a ${elem.label}`} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {elem?.options?.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  </div>
);

export default SingleSelectField;
