import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../../../redux/app/hooks';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { setError } from '../../../redux/features/errorSlice';
import { courseApi } from '../../../api/services/courses';

export const useGetCourses = (
  pageSize,
  currentPage,
  searchValue,
  isFree,
  isShown,
  language,
  isReady,
  subcategory,
  source
) => {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line consistent-return
  const queryFunction = async () => {
    try {
      const response = await courseApi.getAllCourses(
        pageSize,
        currentPage,
        searchValue,
        isFree,
        isShown,
        language,
        isReady,
        subcategory,
        source
      );
      return response.data;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      dispatch(setError({ type: errorMessage, error }));
    }
  };

  return useQuery({
    queryKey: [
      'courses',
      pageSize,
      currentPage,
      searchValue,
      isShown,
      isFree,
      language,
      isReady,
      subcategory,
      source,
    ],
    queryFn: () => queryFunction(),
  });
};
