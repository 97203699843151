import instance from '../axios';

export const userLanguageApi = {
  getAllUserLanguages: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/user-language/get-all', { params: { pageSize, currentPage, searchValue } }),
  getUserLanguageByUuid: async (uuid) => instance.get(`/v1/user-language/${uuid}`),
  createUserLanguage: async (data) => instance.post('/v1/user-language/create', data),
  updateUserLanguage: async (uuid, data) => instance.put(`/v1/user-language/update/${uuid}`, data),
  deleteUserLanguage: async (uuid) => instance.delete(`/v1/user-language/delete/${uuid}`),
};
