export const usePriceCreatorFields = () => [
  { name: 'isShown', label: 'Shown', type: 'checkbox', defaultValue: true },
  { name: 'accessTime', label: 'Access time', type: 'number' },
  { name: 'titleEn', label: 'Title english', type: 'text' },
  { name: 'titleRu', label: 'Title russian', type: 'text' },
  { name: 'descriptionEn', label: 'Description english', type: 'text' },
  { name: 'descriptionRu', label: 'Description russian', type: 'text' },
  { name: 'buttonNameEn', label: 'Button name english', type: 'text' },
  { name: 'buttonNameRu', label: 'Button name russian', type: 'text' },
  { name: 'hrefUrl', label: 'Url', type: 'text' },
  { name: 'packageIncludesEn', label: 'Package includes english', type: 'text' },
  { name: 'packageIncludesRu', label: 'Package includes russian', type: 'text' },
  { name: 'price', label: 'Price', type: 'float' },
  { name: 'discount', label: 'Discount', type: 'float' },
  { name: 'order', label: 'Order', type: 'number' },
];
