import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Page } from '../index';
import { useCourseTableColumns, ColumnType } from './useCourseTableColumns';
import { useGetCourses } from '../../hooks/ReactQuery/Courses/useGetCourses';
import useQueryParams from '../../helpers/useQueryParams';
import { useGetAllSubcategories } from '../../hooks/ReactQuery/Subcategories/useGetAllSubcategories';
import { useGetAllSources } from '../../hooks/ReactQuery/Sources/useGetAllSources';
import { useGetAllLanguages } from '../../hooks/ReactQuery/Language/useGetAllLanguages';

const isFreeOptions = [
  { value: '', label: 'All courses' },
  { value: 'true', label: 'Free' },
  { value: 'false', label: 'Paid' },
];

const isShownOptions = [
  { value: '', label: 'All courses' },
  { value: 'true', label: 'Shown' },
  { value: 'false', label: 'Not shown' },
];

const languageOptions = [
  { value: '', label: 'All languages' },
  { value: 'english', label: 'English' },
  { value: 'russian', label: 'Russian' },
];

const isReadyOptions = [
  { value: '', label: 'All courses' },
  { value: 'true', label: 'Ready' },
  { value: 'false', label: 'Not ready' },
];

const Courses = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const { data: subcategoriesData } = useGetAllSubcategories();
  const { data: sourcesData } = useGetAllSources();
  const { data: languageData } = useGetAllLanguages();

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShown, setIsShown] = useState<string>(localStorage.getItem('isShown') || '');
  const [isFree, setIsFree] = useState<string>(localStorage.getItem('isFree') || '');
  const [language, seLanguage] = useState<string>(localStorage.getItem('language') || '');
  const [isReady, seIsReady] = useState<string>(localStorage.getItem('isReady') || '');

  const [sourceOptions, setSourceOptions] = useState<any[]>([]);
  const [source, setSource] = useState<any>(localStorage.getItem('source') || '');

  const [subcategoryOptions, setSubcategoryOptions] = useState<any[]>([]);
  const [subcategory, setSubcategory] = useState<any>(localStorage.getItem('subcategory') || '');

  const isFreeHandler = (e: any) => {
    localStorage.setItem('isFree', e.value);
    setIsFree(e.value);
  };

  const isShownHandler = (e: any) => {
    localStorage.setItem('isShown', e.value);
    setIsShown(e.value);
  };

  const languageHandler = (e: any) => {
    localStorage.setItem('language', e.value);
    seLanguage(e.value);
  };

  const isReadyHandler = (e: any) => {
    localStorage.setItem('isReady', e.value);
    seIsReady(e.value);
  };

  const subcategoryHandler = (e: any) => {
    localStorage.setItem('subcategory', e.value);
    setSubcategory(e.value);
  };

  const sourceHandler = (e: any) => {
    localStorage.setItem('source', e.value);
    setSource(e.value);
  };

  const { data, error, isLoading } = useGetCourses(
    pageSize,
    currentPage,
    searchValue,
    isFree,
    isShown,
    language,
    isReady,
    subcategory,
    source
  );

  useEffect(() => {
    if (subcategoriesData) {
      const option = [{ value: '', label: 'All subcategory' }];
      subcategoriesData?.subcategories?.forEach((subcategoryItem: any) => {
        option.push({ value: subcategoryItem.uuid, label: subcategoryItem.nameEn });
      });
      setSubcategoryOptions(option);
    }
  }, [subcategoriesData, subcategoriesData?.subcategories]);

  useEffect(() => {
    if (sourcesData) {
      const option = [{ value: '', label: 'All source' }];
      sourcesData?.sources?.forEach((sourceItem: any) => {
        option.push({ value: sourceItem.uuid, label: sourceItem.nameEn });
      });
      setSourceOptions(option);
    }
  }, [sourcesData]);

  useLayoutEffect(() => {
    localStorage.setItem('searchValue', '');
    localStorage.setItem('isFree', '');
    localStorage.setItem('isShown', '');
    localStorage.setItem('language', '');
    localStorage.setItem('isReady', '');
    localStorage.setItem('subcategory', '');
    localStorage.setItem('source', '');
  }, []);

  const formattedData: ColumnType[] = data?.courses?.map((elem: any) => ({
    id: elem?.uuid,
    image: elem?.image,
    zipFile: elem?.zipFile,
    titleEn: elem?.titleEn,
    folderName: elem?.folderName,
    titleRu: elem?.titleRu,
    duration: elem?.duration,
    numberOfLessons: elem?.numberOfLessons,
    languageUuid: elem?.languageUuid,
    language: elem?.language?.nameEn,
    releaseDate: elem?.releaseDate,
    createdAt: elem?.createdAt,
    updateDate: elem?.updateDate,
    rating: elem?.rating,
    isFree: elem?.isFree,
    chLink: elem?.chLink,
    originalLink: elem?.originalLink,
    isShown: elem?.isShown,
  }));

  if (isLoading) {
    return <div>Loading...</div>; // Add a loading state
  }

  if (error) {
    return <div>Error loading courses</div>; // Handle error state
  }

  return (
    <Page
      title="Courses"
      description="Courses info"
      columns={useCourseTableColumns}
      formattedData={formattedData}
      searchKey="titleEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
      isShown={isShown}
      isShownHandler={isShownHandler}
      isShownOptions={isShownOptions}
      isFree={isFree}
      isFreeHandler={isFreeHandler}
      isFreeOptions={isFreeOptions}
      language={language}
      languageHandler={languageHandler}
      languageOptions={languageOptions}
      isReady={isReady}
      isReadyHandler={isReadyHandler}
      isReadyOptions={isReadyOptions}
      subcategoryOptions={subcategoryOptions}
      subcategoryHandler={subcategoryHandler}
      subcategory={subcategory}
      sourceOptions={sourceOptions}
      sourceHandler={sourceHandler}
      source={source}
    />
  );
};

export default Courses;
