import { toast } from 'react-toastify';

export function ToastNotifyError(toastType, message) {
  toast[toastType](message, {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 2000,
  });
}

export function ToastNotifySuccess(toastType, message) {
  toast[toastType](message, {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 2000,
  });
}
