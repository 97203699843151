import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import { categoryValidation } from './categoryValidation';
import { useCategoryCreatorFields } from './useCategoryCreatorFields';
import { navigationApi } from '../../api/services/navigation';
import useOptions from '../../hooks/useOptions';
import { categoriesApi } from '../../api/services/categories';

const CategoriesNew: React.FC = () => {
  const { options: navigationOptions } = useOptions('navigation', () =>
    navigationApi.getAllNavigations(1000, 1)
  );
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { category },
        } = await categoriesApi.getCategoryByUuid(uuid);

        setData(category);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Categories"
        description="Create a categories"
        validation={categoryValidation}
        fields={useCategoryCreatorFields({ navigationOptions })}
        submitFunction={data ? categoriesApi.updateCategory : categoriesApi.createCategory}
        initialData={data}
      />
    )
  );
};

export default CategoriesNew;
