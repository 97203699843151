import { useStateContext } from '../contexts/ContextProvider';

const IconButton = ({ icon, clickHandler, type = 'button' }) => {
  const { currentColor } = useStateContext();

  return (
    <button
      type={type}
      onClick={clickHandler}
      style={{ color: currentColor }}
      className="text-xl rounded-full p-2 hover:bg-light-gray block"
    >
      {icon}
    </button>
  );
};

export default IconButton;
