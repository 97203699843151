import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bookSubcategoryType } from '../../../types/types';

type BookSubcategoriesStateType = {
  bookSubcategories: bookSubcategoryType[];
};

const initialState: BookSubcategoriesStateType = {
  bookSubcategories: [],
};

export const bookSubcategorySlice = createSlice({
  name: 'bookSubcategories',
  initialState,
  reducers: {
    setBookSubcategories: (
      state: BookSubcategoriesStateType,
      action: PayloadAction<bookSubcategoryType[]>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.bookSubcategories = action.payload;
    },
  },
});

export const { setBookSubcategories } = bookSubcategorySlice.actions;
export default bookSubcategorySlice.reducer;
