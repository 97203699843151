import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../../../redux/app/hooks';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { setError } from '../../../redux/features/errorSlice';
import { languageApi } from '../../../api/services/language';

export const useGetAllLanguages = () => {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line consistent-return
  const queryFunction = async () => {
    try {
      const response = await languageApi.getAllLanguages(100, 1, '');
      return response.data;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      dispatch(setError({ type: errorMessage, error }));
    }
  };

  return useQuery({
    queryKey: ['allLanguages'],
    queryFn: () => queryFunction(),
  });
};
