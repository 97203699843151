import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import useOptions from '../../hooks/useOptions';
import { swiperSliderValidation, swiperSliderUpdateValidation } from './swiperSliderValidation';
import { swiperSliderApi } from '../../api/services/swiperSlider';
import { useSwiperSliderCreatorFields } from './useSwiperSliderCreatorFields';
import { categoriesApi } from '../../api/services/categories';
import { subcategoriesApi } from '../../api/services/subcategories';

const SwiperSliderNew: React.FC = () => {
  const { options: categoryOptions } = useOptions('categories', () =>
    categoriesApi.getAllCategories(1000, 1)
  );
  const { options: subcategoryOptions } = useOptions('subcategories', () =>
    subcategoriesApi.getAllSubcategories(1000, 1)
  );

  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { swiperSlider },
        } = await swiperSliderApi.getSwiperSliderByUuid(uuid);

        setData(swiperSlider);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="SwiperSlider"
        description="Create a swiper-slider"
        validation={data ? swiperSliderUpdateValidation : swiperSliderValidation}
        fields={useSwiperSliderCreatorFields({ categoryOptions, subcategoryOptions })}
        submitFunction={
          data ? swiperSliderApi.updateSwiperSlider : swiperSliderApi.createSwiperSlider
        }
        initialData={data}
      />
    )
  );
};

export default SwiperSliderNew;
