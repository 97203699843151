import { AxiosResponse } from 'axios';

import instance from '../axios';
import { userType } from '../../../types/types';

interface dataType {
  user: userType;
}

interface signInDataType {
  email: string;
  password: string;
  remember?: boolean;
}

interface signUpDataType {
  email: string;
  password: string;
  confirmPassword?: boolean;
}

interface authenticationApiType {
  getUser: (userUuid: string) => Promise<AxiosResponse<dataType>>;
  signIn: (data: signInDataType) => Promise<AxiosResponse<dataType>>;
  signUp: (data: signUpDataType) => Promise<AxiosResponse<any>>;
  signOut: () => Promise<AxiosResponse<any>>;
  resetEmail: (data: any) => Promise<AxiosResponse<any>>;
  resetPassword: (data: any, searchParams: any) => Promise<AxiosResponse<any>>;
}

export const authenticationApi: authenticationApiType = {
  getUser: async (userUuid) => instance.get(`v1/admin-auth/get-by-uuid/${userUuid}`),
  signIn: async (data) => instance.post(`v1/admin-auth/sign-in`, data),
  signUp: async (data) => instance.post(`v1/admin-auth/sign-up`, data),
  signOut: async () => instance.post(`v1/admin-auth/sign-out`),
  resetEmail: async (data) => instance.post(`v1/admin-auth/reset-email`, data),
  resetPassword: async (data, searchParams) =>
    instance.post(
      `v1/admin-auth/reset-password?email=${searchParams.email}&resetToken=${searchParams.resetToken}`,
      data
    ),
};
