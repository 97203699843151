import React, { useState } from 'react';
import moment from 'moment';

import { Page } from '../index';
import { useBookTableColumns, ColumnType } from './useBookTableColumns';
import Loading from '../../components/Loading/Loading';
import { useGetBookSubcategories } from '../../hooks/ReactQuery/Books/useGetBooks';
import useQueryParams from '../../helpers/useQueryParams';

const Books = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetBookSubcategories(
    pageSize,
    currentPage,
    searchValue
  );

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] = data?.books?.map((elem: any) => ({
    id: elem?.uuid,
    image: elem?.image,
    zipFile: elem?.zipFile,
    bookSubcategories: elem?.bookSubcategories,
    source: elem?.source,
    titleEn: elem?.titleEn,
    titleRu: elem?.titleRu,
    pages: elem?.pages,
    isShown: elem?.isShown,
    language: elem?.language?.nameEn,
    releaseDate: moment(elem?.releaseDate).format('DD-MM-yyyy'),
    updateDate: elem?.updateDate,
    isFree: elem?.isFree,
    chLink: elem?.chLink,
  }));

  return (
    <Page
      title="Books"
      description="Books info"
      columns={useBookTableColumns}
      formattedData={formattedData}
      searchKey="titleEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default Books;
