import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import { userLanguageApi } from '../../api/services/userLanguage';
import { userLanguageValidation } from './userLanguageValidation';
import { useUserLanguageCreatorFields } from './useUserLanguageCreatorFields';

const UserLanguageNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { userLanguage },
        } = await userLanguageApi.getUserLanguageByUuid(uuid);

        setData(userLanguage);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="User language"
        description="Create a user language"
        validation={userLanguageValidation}
        fields={useUserLanguageCreatorFields()}
        submitFunction={
          data ? userLanguageApi.updateUserLanguage : userLanguageApi.createUserLanguage
        }
        initialData={data}
      />
    )
  );
};

export default UserLanguageNew;
