import * as yup from 'yup';

const MAX_FILE_SIZE = 5120000; // 5MB

const validImageExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

const validPdfExtensions = { pdf: ['pdf'] };

function isValidFileTypeImage(fileName, fileType) {
  return fileName && validImageExtensions?.[fileType]?.indexOf(fileName.split('.').pop()) > -1;
}

function isValidFileTypePdf(fileName, fileType) {
  return fileName && validPdfExtensions?.[fileType]?.indexOf(fileName.split('.').pop()) > -1;
}

export const bookValidation = {
  // image: yup
  //   .mixed()
  //   .required('Required')
  //   .test('is-valid-type', 'Not a valid image type', (value) =>
  //     isValidFileTypeImage(value && value[0]?.name?.toLowerCase(), 'image')
  //   )
  //   .test(
  //     'is-valid-size',
  //     'Max allowed size is 5MB',
  //     (value) => value && value?.[0]?.size <= MAX_FILE_SIZE
  //   ),
  // pdf: yup
  //   .mixed()
  //   .required('Required')
  //   .test('is-valid-type', 'Not a valid image type', (value) =>
  //     isValidFileTypePdf(value && value[0]?.name?.toLowerCase(), 'pdf')
  //   )
  //   .test(
  //     'is-valid-size',
  //     'Max allowed size is 5MB',
  //     (value) => value && value?.[0]?.size <= MAX_FILE_SIZE
  //   ),
  isShown: yup.boolean().required('Required'),
  isFree: yup.boolean().required('Required'),
  titleEn: yup.string().required('Required'),
  titleRu: yup.string().required('Required'),
  sources: yup.array().of(
    yup.object({
      value: yup.string().required('Required'),
      label: yup.string().required('Required'),
    })
  ),
  bookSubcategory: yup.array().of(
    yup.object({
      value: yup.string().required('Required'),
      label: yup.string().required('Required'),
    })
  ),
  pages: yup.string().required('Required'),
  languageUuid: yup.string().required('Required'),
  descriptionEn: yup.string().required('Required'),
  descriptionRu: yup.string().required('Required'),
};

export const bookUpdateValidation = {
  isShown: yup.boolean().required('Required'),
  isFree: yup.boolean().required('Required'),
  titleEn: yup.string().required('Required'),
  titleRu: yup.string().required('Required'),
  sources: yup.array().of(
    yup.object({
      value: yup.string().required('Required'),
      label: yup.string().required('Required'),
    })
  ),
  bookSubcategory: yup.array().of(
    yup.object({
      value: yup.string().required('Required'),
      label: yup.string().required('Required'),
    })
  ),
  pages: yup.string().required('Required'),
  languageUuid: yup.string().required('Required'),
  descriptionEn: yup.string().required('Required'),
  descriptionRu: yup.string().required('Required'),
};
