import React from 'react';
import ReactQuill from 'react-quill';
import { Controller } from 'react-hook-form';
import { Label } from '../../../@/components/ui/label';

import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ elem, form, loading }) => (
  <div className="mb-10">
    {
      <div className="flex flex-col">
        <Label className="m-2">{elem.label}</Label>
        <Controller
          name={elem?.name}
          control={form.control}
          className="w-full mb-2 p-1.5 text-gray-700 rounded-md"
          render={({ field }) => (
            <>
              <ReactQuill
                {...field}
                className="react-quill"
                label={elem?.label}
                theme="snow"
                name={elem.name}
              />
            </>
          )}
        />
      </div>
    }
    {form?.formState?.errors?.[elem.name] && (
      <Label className="text-xs text-red-600 mt-1">
        {form?.formState?.errors?.[elem.name]?.message || 'Required'}
      </Label>
    )}
  </div>
);

export default TextEditor;
