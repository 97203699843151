import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useGetUsersDaysVisits } from '../../hooks/ReactQuery/Users/useGetUsersDaysVisits';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Attendance chart',
    },
  },
};

export const LineChartData = (dataLabels, dataDays) => ({
  labels: dataLabels,
  datasets: [
    {
      label: 'Dataset 1',
      data: dataDays,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    },
  ],
});

const AttendanceSparkLine = () => {
  const { data: usersDaysVisits, isFetching, isPending } = useGetUsersDaysVisits();
  const dateCounts = {};
  usersDaysVisits?.visitInformation?.forEach((entry) => {
    const date = entry.visitDate;
    if (dateCounts[date]) {
      dateCounts[date] += 1;
    } else {
      dateCounts[date] = 1;
    }
  });

  const entries = Object.entries(dateCounts);
  const sortedEntries = entries.sort((a, b) => {
    const [dayA, monthA, yearA] = a[0].split('/').map(Number);
    const [dayB, monthB, yearB] = b[0].split('/').map(Number);

    const dateA = new Date(yearA, monthA - 1, dayA);
    const dateB = new Date(yearB, monthB - 1, dayB);

    return dateA - dateB;
  });
  const sortedData = Object.fromEntries(sortedEntries);

  const days = Object.keys(sortedData);
  const daysCount = Object.values(sortedData);

  return (
    <Line options={options} data={LineChartData(days, daysCount)} width={'100%'} height={'300px'} />
  );
};

export default AttendanceSparkLine;
