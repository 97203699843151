import React, { useState } from 'react';

import { Page } from '../index';
import { categoryTableColumns, ColumnType } from './categoryTableColumns';
import Loading from '../../components/Loading/Loading';
import { useGetCategories } from '../../hooks/ReactQuery/Categories/useGetCategories';
import useQueryParams from '../../helpers/useQueryParams';

const Categories = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetCategories(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] = data?.categories?.map((elem: any) => ({
    id: elem?.uuid,
    nameEn: elem?.nameEn,
    nameRu: elem?.nameRu,
    isDropdown: elem.isDropdown,
    isShown: elem.isShown,
    order: elem.order,
  }));

  return (
    <Page
      title="Categories"
      description="Categories info"
      columns={categoryTableColumns}
      formattedData={formattedData}
      searchKey="nameEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default Categories;
