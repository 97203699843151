import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  image: string;
  titleEn: string;
  titleRu: string;
  pages: string;
  language: string;
  releaseDate: string;
  createdAt?: string;
  updateDate: string;
  isShown: string;
  isFree: string;
  chLink: string;
};

export const useBookTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'image', header: 'Image' },
  { accessorKey: 'titleEn', header: 'Title english' },
  { accessorKey: 'titleRu', header: 'CTitle russian' },
  { accessorKey: 'pages', header: 'Pages' },
  { accessorKey: 'language', header: 'Language' },
  { accessorKey: 'releaseDate', header: 'Release date' },
  { accessorKey: 'updateDate', header: 'Update date' },
  { accessorKey: 'isShown', header: 'isShown' },
  { accessorKey: 'isFree', header: 'Is free' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'book'} />,
  },
];
