import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import useOptions from '../../hooks/useOptions';
import { positionStatusApi } from '../../api/services/positionStatus';
import { useSourcesCreatorFields } from './useSourcesCreatorFields';
import { sourceApi } from '../../api/services/sources';
import { sourcesUpdateValidation, sourcesValidation } from './sourcesValidation';

const SourcesNew: React.FC = () => {
  const { options: positionStatusOptions } = useOptions('positionStatus', () =>
    positionStatusApi.getAllPositionStatus(1000, 1)
  );
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { source },
        } = await sourceApi.getSourceByUuid(uuid);

        setData(source);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Sources"
        description="Create a sources"
        validation={data ? sourcesUpdateValidation : sourcesValidation}
        fields={useSourcesCreatorFields({ positionStatusOptions })}
        submitFunction={data ? sourceApi.updateSource : sourceApi.createSource}
        initialData={data}
      />
    )
  );
};

export default SourcesNew;
