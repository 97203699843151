import instance from '../axios';

export const categoriesApi = {
  getAllCategories: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/category/get-all', { params: { pageSize, currentPage, searchValue } }),
  getCategoryByUuid: async (uuid) => instance.get(`/v1/category/${uuid}`),
  createCategory: async (data) => instance.post('/v1/category/create', data),
  updateCategory: async (uuid, data) => instance.put(`/v1/category/update/${uuid}`, data),
  deleteCategory: async (uuid) => instance.delete(`/v1/category/delete/${uuid}`),
};
