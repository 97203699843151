import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  order: string;
  image: string;
  nameEn: string;
  nameRu: string;
  isDropdown: string;
  isShown: string;
};

export const useBookSubcategoryTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'order', header: 'order' },
  { accessorKey: 'image', header: 'Image' },
  { accessorKey: 'nameEn', header: 'Category book name english' },
  { accessorKey: 'nameRu', header: 'Category book name russian' },
  { accessorKey: 'isShown', header: 'Is shown' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'bookSubcategory'} />,
  },
];
