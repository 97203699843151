import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import useOptions from '../../hooks/useOptions';
import { useBookSubcategoryCreatorFields } from './useBookSubcategoryCreatorFields';
import { bookCategoriesApi } from '../../api/services/bookCategories';
import {
  bookSubcategoryUpdateValidation,
  bookSubcategoryValidation,
} from './bookSubcategoryValidation';
import { bookSubcategoriesApi } from '../../api/services/bookSubcategories';
import { changeMultiSelectOptions } from '../../helpers/changeMultiSelectOptions';

const BookSubcategoriesNew: React.FC = () => {
  const { options: bookCategoryOptions } = useOptions('bookCategories', () =>
    bookCategoriesApi.getAllBookCategories(1000, 1)
  );
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { bookSubcategory },
        } = await bookSubcategoriesApi.getBookSubcategoryByUuid(uuid);

        setData(bookSubcategory);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Subcategory book"
        description="Create a Subcategory book"
        validation={data ? bookSubcategoryUpdateValidation : bookSubcategoryValidation}
        fields={useBookSubcategoryCreatorFields({ bookCategoryOptions })}
        submitFunction={
          data
            ? bookSubcategoriesApi.updateBookSubcategory
            : bookSubcategoriesApi.createBookSubcategory
        }
        initialData={
          data
            ? {
                ...data,
                bookCategories: changeMultiSelectOptions(data?.bookCategories),
              }
            : null
        }
      />
    )
  );
};

export default BookSubcategoriesNew;
