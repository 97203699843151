import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { pageContentEn } from '../../../constants/pageContent';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { subcategoriesApi } from '../../../api/services/subcategories';

export const useDeleteSubcategory = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line consistent-return
  const mutationFunction = async (deletedSubcategoryUuid: string) => {
    try {
      const response = await subcategoriesApi.deleteSubcategory(deletedSubcategoryUuid);
      toast.success(response?.data?.message || pageContentEn.SUBCATEGORY_DELETED_SUCCESSFULLY);
      return response;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const { mutateAsync: deleteSubcategoryMutate } = useMutation({
    mutationFn: mutationFunction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subcategories'] });
    },
  });

  return { deleteSubcategoryMutate };
};
