import React, { useEffect, useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';

import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { Button } from '../components';

import { useStateContext } from '../contexts/ContextProvider';
import AttendanceSparkLine from '../components/Charts/AttendanceSparkLine';
import { purchaseApi } from '../api/services/purchaseApi';
import { purchaseType, userType } from '../../types/types';
import { userApi } from '../api/services/userApi';

const countTotalPurchase = (purchaseData: purchaseType[] = []) => {
  let totalPurchase = 0;
  if (purchaseData?.length > 0) {
    purchaseData.forEach((purchase) => {
      totalPurchase += purchase?.price?.price as number;
    });
  }

  return totalPurchase.toFixed(2);
};

const countCustomers = (customersData: userType[] = []) => {
  let totalCustomers;
  if (customersData?.length > 0) {
    totalCustomers = customersData?.length;
  }

  return totalCustomers;
};

const Dashboard = () => {
  const { currentColor } = useStateContext();
  const [purchases, setPurchases] = useState<purchaseType[] | null>(null);
  const [users, setUsers] = useState<userType[] | null>(null);

  useEffect(() => {
    const getPurchasesData = async () => {
      const { data } = await purchaseApi.getAllPurchases(10000, 1, '');
      setPurchases(data?.purchases);
    };

    getPurchasesData().catch((error) => console.error('error', error));
  }, []);

  useEffect(() => {
    const getUsersData = async () => {
      const { data } = await userApi.getAllUsers();
      setUsers(data?.users);
    };

    getUsersData().catch((error) => console.error('error', error));
  }, []);

  return (
    <div>
      <div className="flex flex-wrap lg:flex-nowrap justify-start">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-start items-center">
            <div>
              <p className="font-bold text-gray-400">Earnings</p>
              <p className="text-2xl">{countTotalPurchase(purchases as purchaseType[])}</p>
            </div>
            <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full ml-1 p-4"
              onClick={() => {
                console.log('onClick');
              }}
            >
              <BsCurrencyDollar />
            </button>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
              size={undefined}
              onClick={() => {
                console.log('onClick');
              }}
            />
          </div>
        </div>
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          {/* users */}
          <div
            key={'Customers'}
            className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
          >
            <button
              type="button"
              style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <MdOutlineSupervisorAccount />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">{countCustomers(users as userType[])}</span>
            </p>
            <p className="text-sm text-gray-400  mt-1">Registered customers</p>
          </div>
        </div>
      </div>

      <div className="flex gap-10 flex-wrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl w-full md:w780">
          <div className="flex justify-between">
            <p className="font-semibold text-xl">Charts</p>
          </div>
          <div className="flex flex-wrap justify-start">
            <div className="border-r-1 border-color w-full">
              <div className="mt-5">
                <AttendanceSparkLine />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
