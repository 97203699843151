import React from 'react';

import SkillIcon from './SkillIcon.svg';

import styles from './loading.module.scss';

const Loading = ({ width = 48, height = 48 }) => (
  <div className={styles.loading_wrapper}>
    <div className={styles.loading_background} />
    <div className={styles.lds_dual_ring}>
      <img src={SkillIcon} alt="loading" />
    </div>
  </div>
);

export default Loading;
