import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../@/components/ui/form';
import { Checkbox } from '../../../@/components/ui/checkbox';

const CheckboxField = ({ form, elem, loading }) => (
  <div className="mb-1">
    {
      <div className="flex flex-col lg:flex-row mb-4 lg:mb-0 items-start lg:items-center ">
        <FormField
          control={form.control}
          name={elem.name}
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor={elem.name}>{elem.label}</FormLabel>
              <FormControl>
                <Checkbox
                  className="ml-1"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  disabled={loading || elem.disabled}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    }
  </div>
);

export default CheckboxField;
