import { createSlice } from '@reduxjs/toolkit';

type SettingsStateType = {
  isSettingsOpen: boolean;
};

const initialState: SettingsStateType = {
  isSettingsOpen: false,
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startEndSettings: (state: SettingsStateType) => {
      // eslint-disable-next-line no-param-reassign
      state.isSettingsOpen = !state.isSettingsOpen;
    },
    endSettings: (state: SettingsStateType) => {
      // eslint-disable-next-line no-param-reassign
      state.isSettingsOpen = false;
    },
  },
});

export const { startEndSettings, endSettings } = settings.actions;
export default settings.reducer;
