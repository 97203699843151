import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { Button } from '../../@/components/ui/button';
import { Input } from '../../@/components/ui/input';
import { Avatar, AvatarImage, AvatarFallback } from '../../@/components/ui/avatar';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../@/components/ui/table';
import useDebounce from '../../hooks/useDebounce';
import SBSelectFilter from '../UI/SBSelectFilter';

interface optionType {
  value: string;
  label: string;
}

function findIndexByValue(value: string, options: optionType[]) {
  return options.findIndex((option) => option.value === value);
}

const baseURL = process.env.REACT_APP_IMAGE_URL;

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  searchKey: string;
  currentPage: number;
  setCurrentPage: (prev: any) => void;
  dataTotalCount: number;
  pageSize: number;
  setSearchValue: (prev: string) => void;
  isShown: string;
  isShownHandler: any;
  isShownOptions?: optionType[];
  isFree: string;
  isFreeHandler: any;
  isFreeOptions?: optionType[];
  language: string;
  languageHandler: any;
  languageOptions?: optionType[];
  isReady: string;
  isReadyHandler: any;
  isReadyOption?: optionType[];
  subcategoryOptions?: optionType[];
  subcategoryHandler: any;
  subcategory: any;
  sourceOptions?: optionType[];
  sourceHandler: any;
  source: any;
}

const theme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            color: '#31c469',
          },
          '.MuiPaginationItem-root': {
            color: '#31c469',
          },
          '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: '#31c469', // Replace YOUR_COLOR with your desired color
            color: '#ffffff', // Replace YOUR_TEXT_COLOR with the desired text color
            '&:hover': {
              backgroundColor: '#31c469', // Replace YOUR_HOVER_COLOR with the desired hover color
              color: '#ffffff',
            },
          },
        },
      },
    },
  },
});

export function DataTable<TData, TValue>({
  columns,
  data,
  searchKey,
  currentPage = 1,
  setCurrentPage,
  dataTotalCount,
  pageSize,
  setSearchValue,
  isShown,
  isShownHandler,
  isShownOptions,
  isFree,
  isFreeHandler,
  isFreeOptions,
  language,
  languageHandler,
  languageOptions,
  isReady,
  isReadyHandler,
  isReadyOption,
  subcategoryOptions,
  subcategoryHandler,
  subcategory,
  sourceOptions,
  sourceHandler,
  source,
}: DataTableProps<TData, TValue>) {
  const navigate = useNavigate();
  const location = useLocation();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [tableSearchValue, setTableSearchValue] = useState<string>(
    localStorage.getItem('searchValue') || ''
  );

  const debouncedSearch = useDebounce(tableSearchValue, 500);

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
    },
  });

  const changeNextPage = () => {
    setCurrentPage((prev: number) => prev + 1);
  };

  const changePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev: number) => prev - 1);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('searchValue', event.target.value);
    setTableSearchValue(event.target.value);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    event.preventDefault();
    setCurrentPage(`${page}`);
  };

  useEffect(() => {
    if (typeof dataTotalCount === 'number' && typeof pageSize === 'number') {
      setTotalPages(Math.ceil(dataTotalCount / pageSize));
    }
  }, [dataTotalCount, pageSize]);

  useEffect(() => {
    setSearchValue(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ currentPage: currentPage.toString() }).toString(),
    });
  }, [currentPage]);

  return (
    <div>
      <div className="flex items-end py-4">
        <Input
          placeholder={`Search...`}
          value={tableSearchValue ?? ''}
          onChange={(event) => handleChange(event)}
          className="max-w-sm"
        />
      </div>
      <div className="flex items-end pb-4">
        <div className="ml-2">
          {isFreeOptions && (
            <SBSelectFilter
              defaultValue={isFreeOptions[findIndexByValue(isFree, isFreeOptions)]}
              options={isFreeOptions}
              onChangeHandler={isFreeHandler}
              description="Is free"
            />
          )}
        </div>
        <div className="ml-2">
          {isShownOptions && (
            <SBSelectFilter
              defaultValue={isShownOptions[findIndexByValue(isShown, isShownOptions)]}
              options={isShownOptions}
              onChangeHandler={isShownHandler}
              description="Is shown"
            />
          )}
        </div>
        <div className="ml-2">
          {languageOptions && (
            <SBSelectFilter
              defaultValue={languageOptions[findIndexByValue(language, languageOptions)]}
              options={languageOptions}
              onChangeHandler={languageHandler}
              description="Language"
            />
          )}
        </div>
        <div className="ml-2">
          {isReadyOption && (
            <SBSelectFilter
              defaultValue={isReadyOption[findIndexByValue(isReady, isReadyOption)]}
              options={isReadyOption}
              onChangeHandler={isReadyHandler}
              description="Ready/Not ready"
            />
          )}
        </div>
        <div className="ml-2">
          {subcategoryOptions && (
            <SBSelectFilter
              defaultValue={subcategoryOptions?.[findIndexByValue(subcategory, subcategoryOptions)]}
              options={subcategoryOptions}
              onChangeHandler={subcategoryHandler}
              description="Subcategory"
            />
          )}
        </div>
        <div className="ml-2">
          {sourceOptions && (
            <SBSelectFilter
              defaultValue={sourceOptions[findIndexByValue(source, sourceOptions)]}
              options={sourceOptions}
              onChangeHandler={sourceHandler}
              description="Source"
            />
          )}
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table?.getRowModel()?.rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const src = `${baseURL}/${cell.row.original?.image}`;
                    return cell.column.id === 'image' ? (
                      <TableCell key={cell.id} className="py-1 h-[41px] ">
                        <Avatar className="h-8 w-8">
                          <AvatarImage src={src} />
                          <AvatarFallback>SB</AvatarFallback>
                        </Avatar>
                      </TableCell>
                    ) : (
                      <TableCell key={cell.id} className="py-1 h-[41px]">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-center space-x-2 py-4">
        <ThemeProvider theme={theme}>
          {totalPages > 1 && (
            <Pagination
              defaultPage={+currentPage || 1}
              count={totalPages}
              page={+currentPage}
              onChange={handlePageChange}
            />
          )}
        </ThemeProvider>
        <div>{`${totalPages} / ${currentPage}`}</div>
        <Button
          variant="outline"
          size="sm"
          onClick={changePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={changeNextPage}
          disabled={totalPages === currentPage}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
