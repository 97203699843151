import instance, { formDataInstance } from '../axios';

export const sourceApi = {
  getAllSources: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/source/get-all', { params: { pageSize, currentPage, searchValue } }),
  getSourceByUuid: async (uuid) => instance.get(`/v1/source/${uuid}`),
  createSource: async (data) => instance.post('/v1/source/create', data),
  updateSource: async (uuid, data) => formDataInstance.put(`/v1/source/update/${uuid}`, data),
  deleteSource: async (uuid) => instance.delete(`/v1/source/delete/${uuid}`),
};
