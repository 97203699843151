import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  about: string;
};

export const useAboutTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'about', header: 'about' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'about'} />,
  },
];
