import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../@/components/ui/form';
import { Input } from '../../../@/components/ui/input';

const NumberFloatField = ({ form, elem, loading }) => (
  <div className="mb-10">
    {
      <div className="flex flex-col">
        <FormField
          control={form.control}
          name={elem.name}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{elem.label}</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  disabled={loading}
                  placeholder={elem.label}
                  {...field}
                  min={0}
                  step="0.01"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    }
  </div>
);

export default NumberFloatField;
