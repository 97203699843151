import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import { bookCategoriesValidation } from './bookCategoriesValidation';
import useOptions from '../../hooks/useOptions';
import { navigationApi } from '../../api/services/navigation';
import { useBookCategoriesCreatorFields } from './useBookCategoriesCreatorFields';
import { bookCategoriesApi } from '../../api/services/bookCategories';

const BookCategoriesNew: React.FC = () => {
  const { options: navigationOptions } = useOptions('navigation', () =>
    navigationApi.getAllNavigations(1000, 1)
  );
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { bookCategory },
        } = await bookCategoriesApi.getBookCategoryByUuid(uuid);

        setData(bookCategory);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Category book"
        description="Create a Category book"
        validation={bookCategoriesValidation}
        fields={useBookCategoriesCreatorFields({ navigationOptions })}
        submitFunction={
          data ? bookCategoriesApi.updateBookCategory : bookCategoriesApi.createBookCategory
        }
        initialData={data}
      />
    )
  );
};

export default BookCategoriesNew;
