import React, { useState } from 'react';

import { Page } from '../index';
import { useSwiperSliderTableColumns, ColumnType } from './useSwiperSliderTableColumns';
import Loading from '../../components/Loading/Loading';
import { useGetSwiperSlides } from '../../hooks/ReactQuery/SwiperSlide/useGetSwiperSlides';
import useQueryParams from '../../helpers/useQueryParams';

const SwiperSlider = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetSwiperSlides(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] = data?.swiperSlider?.map((elem: any) => ({
    id: elem?.uuid,
    order: elem?.order,
    image: elem?.image,
    category: elem?.category.nameEn,
    subcategory: elem?.subcategory.nameEn,
    isShown: elem?.isShown,
  }));

  return (
    <Page
      title="Swiper Slider"
      description="Swiper slider info"
      columns={useSwiperSliderTableColumns}
      formattedData={formattedData}
      searchKey="categories"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default SwiperSlider;
