import instance from '../axios';

export const languageApi = {
  getAllLanguages: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/language/get-all', { params: { pageSize, currentPage, searchValue } }),
  getLanguageByUuid: async (uuid) => instance.get(`/v1/language/${uuid}`),
  createLanguage: async (data) => instance.post('/v1/language/create', data),
  updateLanguage: async (uuid, data) => instance.put(`/v1/language/update/${uuid}`, data),
  deleteLanguage: async (uuid) => instance.delete(`/v1/language/delete/${uuid}`),
};
