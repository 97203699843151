import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import useOptions from '../../hooks/useOptions';
import { categoriesApi } from '../../api/services/categories';
import { useSubcategoryCreatorFields } from './useSubcategoryCreatorFields';
import { subcategoryValidation, subcategoryUpdateValidation } from './subcategoryValidation';
import { subcategoriesApi } from '../../api/services/subcategories';
import { changeMultiSelectOptions } from '../../helpers/changeMultiSelectOptions';

const SubcategoriesNew: React.FC = () => {
  const { options: categoryOptions } = useOptions('categories', () =>
    categoriesApi.getAllCategories(1000, 1)
  );
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { subcategory },
        } = await subcategoriesApi.getSubcategoryByUuid(uuid);

        setData(subcategory);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Subcategories"
        description="Create a subcategories"
        validation={data ? subcategoryUpdateValidation : subcategoryValidation}
        fields={useSubcategoryCreatorFields({ categoryOptions })}
        submitFunction={
          data ? subcategoriesApi.updateSubcategory : subcategoriesApi.createSubcategory
        }
        initialData={
          data
            ? {
                ...data,
                categories: changeMultiSelectOptions(data?.categories),
              }
            : null
        }
      />
    )
  );
};

export default SubcategoriesNew;
