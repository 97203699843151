import * as yup from 'yup';

export const bookCategoriesValidation = {
  nameEn: yup.string().required('Required'),
  nameRu: yup.string().required('Required'),
  descriptionEn: yup.string().required('Required'),
  descriptionRu: yup.string().required('Required'),
  isDropdown: yup.boolean().required('Required'),
  isShown: yup.boolean().required('Required'),
  navigationUuid: yup.string().required('Required'),
  order: yup.number().min(0).required('Required'),
};
