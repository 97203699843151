// Leave this as an example for now

import { createApi } from '@reduxjs/toolkit/query/react';
import { languageType } from '../../../../types/types';
import instance from '../../../api/axios';

export const apiSlice = createApi({
  reducerPath: 'languagesApi',
  baseQuery: instance,
  endpoints: (build) => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getLanguages: build.query<languageType[]>({
      query: () => 'v1/language/get-all',
    }),
  }),
});

export const { useGetLanguagesQuery } = apiSlice;
