import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import { navigationValidation } from './navigationValidation';
import { useNavigationCreatorFields } from './useNavigationCreatorFields';
import { navigationApi } from '../../api/services/navigation';

const NavigationNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { navigation },
        } = await navigationApi.getNavigationsByUuid(uuid);

        setData(navigation);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Navigation"
        description="Create a navigation"
        validation={navigationValidation}
        fields={useNavigationCreatorFields()}
        submitFunction={data ? navigationApi.updateNavigation : navigationApi.createNavigation}
        initialData={data}
      />
    )
  );
};
export default NavigationNew;
