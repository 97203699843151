import { useNavigate } from 'react-router-dom';
import { authenticationApi } from '../api/services/authenticationApi';
import { useAppDispatch } from '../redux/app/hooks';
import { clearUser, setUser } from '../redux/features/userSlice';

interface signInDataType {
  email: string;
  password: string;
  remember?: boolean;
}

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const signIn = async (data: signInDataType) => {
    const response = await authenticationApi.signIn(data);

    if (response.status === 200) {
      const userData = await authenticationApi.getUser(response?.data?.user?.userUuid as string);

      if (userData?.data?.user) {
        dispatch(setUser(userData?.data?.user));
      }
    }

    if (data.remember) {
      localStorage.setItem('sb_admin_token', response?.data?.user?.token as string);
      localStorage.setItem('sb_admin_user', JSON.stringify(response?.data?.user));
      sessionStorage.removeItem('sb_admin_token');
      sessionStorage.removeItem('sb_admin_user');
    } else {
      sessionStorage.setItem('sb_admin_token', response?.data?.user?.token as string);
      sessionStorage.setItem('sb_admin_user', JSON.stringify(response?.data?.user));
      localStorage.removeItem('sb_admin_token');
      localStorage.removeItem('sb_admin_user');
    }
    if (response) {
      navigate('/');
      window.location.reload();
    }
  };

  const signUp = async (data: any) => {
    const response = await authenticationApi.signUp(data);
    const res = await authenticationApi.signIn(data);

    localStorage.removeItem('sb_admin_token');
    localStorage.removeItem('sb_admin_user');
    sessionStorage.removeItem('sb_admin_token');
    sessionStorage.removeItem('sb_admin_user');

    await signIn(data);

    if (res) {
      navigate('/signup-success');
    }
    return response;
  };

  const signOut = async () => {
    const response = await authenticationApi.signOut();
    dispatch(clearUser());
    if (response) {
      localStorage.removeItem('sb_admin_token');
      localStorage.removeItem('sb_admin_user');
      sessionStorage.removeItem('sb_admin_token');
      sessionStorage.removeItem('sb_admin_user');
      window.location.reload();
    }
  };

  const resetEmail = async (data: any) => {
    const response = await authenticationApi.resetEmail(data);
    if (response) {
      navigate('/reset/email/success');
    } else {
      navigate('/reset/email/failure');
    }
  };

  const resetPassword = async (data: any, searchParams: string) => {
    const response = await authenticationApi.resetPassword(data, searchParams);
    if (response) {
      navigate('/reset/password/success');
    } else {
      navigate('/reset/password/failure');
    }
  };

  return {
    signIn,
    signUp,
    signOut,
    resetEmail,
    resetPassword,
  };
};

export default useAuth;
