export const useSubcategoryCreatorFields = ({ categoryOptions }) => [
  { name: 'isShown', label: 'Shown', type: 'checkbox', defaultValue: true },
  { name: 'image', label: 'Image', type: 'file' },
  { name: 'nameEn', label: 'Name English', type: 'text' },
  { name: 'nameRu', label: 'Name Russian', type: 'text' },
  { name: 'descriptionEn', label: 'Description english', type: 'textarea' },
  { name: 'descriptionRu', label: 'Description russian', type: 'textarea' },
  {
    name: 'categories',
    label: 'Categories',
    type: 'multiSelect',
    options: [...categoryOptions],
  },
  { name: 'order', label: 'Order', type: 'number' },
];
