export const useSourcesCreatorFields = ({ positionStatusOptions }) => [
  { name: 'isShown', label: 'Shown', type: 'checkbox', defaultValue: true },
  { name: 'image', label: 'Image', type: 'file' },
  { name: 'nameEn', label: 'Name english', type: 'text' },
  { name: 'nameRu', label: 'Name russian', type: 'text' },
  { name: 'descriptionEn', label: 'Description english', type: 'textarea' },
  { name: 'descriptionRu', label: 'Description russian', type: 'textarea' },
  {
    name: 'positionStatusUuid',
    label: 'Position status',
    type: 'singleSelect',
    options: positionStatusOptions,
  },
  { name: 'order', label: 'Order', type: 'number' },
];
