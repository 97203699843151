import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  image: string;
  title: string;
  originalTitle: string;
  status: string;
};

export const useOrderTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'image', header: 'Image' },
  { accessorKey: 'title', header: 'Title' },
  { accessorKey: 'originalTitle', header: 'Original Title' },
  { accessorKey: 'status', header: 'status' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'order'} />,
  },
];
