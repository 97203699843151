import React, { useState } from 'react';
import { Page } from '../index';
import { useUserTableColumns, ColumnType } from './useUserTableColumns';
import Loading from '../../components/Loading/Loading';
import { useGetUsers } from '../../hooks/ReactQuery/Users/useGetUsers';
import useQueryParams from '../../helpers/useQueryParams';

const User = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetUsers(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] | undefined = data?.users?.map((elem: any) => ({
    id: elem?.uuid,
    firstName: elem?.firstName,
    lastName: elem?.lastName,
    email: elem?.email,
    isPremium: elem?.isPremium,
  }));

  return (
    <Page
      title="User"
      description="User info"
      columns={useUserTableColumns}
      formattedData={formattedData}
      searchKey="firstName"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default User;
