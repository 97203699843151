import { Controller } from 'react-hook-form';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Label } from '../../../@/components/ui/label';

const animatedComponents = makeAnimated();

const MultiSelectField = ({ elem, form, loading }) => (
  <div className="mb-5">
    <div className="flex flex-col">
      <Label className="m-2">{elem.label}</Label>
      <Controller
        name={elem?.name}
        control={form.control}
        render={({ field }) => (
          <Select
            {...field}
            className="w-full"
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={elem.options}
            placeholder={`Select ${elem?.label}...`}
          />
        )}
      />
    </div>
    {form?.formState?.errors?.[elem.name] && (
      <Label className="text-xs text-red-600 mt-1">
        {form?.formState?.errors?.[elem?.name]?.message || 'Required'}
      </Label>
    )}
  </div>
);

export default MultiSelectField;
