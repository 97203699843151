import React from 'react';

const Button = ({ color, bgColor, text, borderRadius, size, type = 'button', onClick }) => (
  <button
    type={type}
    style={{ background: bgColor, color, borderRadius }}
    className={`text-${size} p-3 hover:drop-shadow-xl`}
    onClick={onClick}
  >
    {text}
  </button>
);

export default Button;
