import instance, { formDataInstance } from '../axios';

export const courseApi = {
  getAllCourses: async (
    pageSize,
    currentPage,
    searchValue,
    isFree,
    isShown,
    language,
    isReady,
    subcategory,
    source
  ) =>
    instance.get('/v1/course/get-all-admin', {
      params: {
        pageSize,
        currentPage,
        searchValue,
        isFree,
        isShown,
        language,
        isReady,
        subcategory,
        source,
      },
    }),
  getCourseByUuid: async (uuid) => instance.get(`/v1/course/get-by-uuid/${uuid}`),
  createCourse: async (data) => instance.post('/v1/course/create', data),
  updateCourse: async (uuid, data) => formDataInstance.put(`/v1/course/update/${uuid}`, data),
  deleteCourse: async (uuid) => instance.delete(`/v1/course/delete/${uuid}`),
  updateCourseInfo: async (uuid) => instance.put(`/v1/course/update-course-info`),
};
