import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { FormCreator } from '../../components';
import useOptions from '../../hooks/useOptions';
import { bookUpdateValidation, bookValidation } from './bookValidation';
import { bookSubcategoriesApi } from '../../api/services/bookSubcategories';
import { sourceApi } from '../../api/services/sources';
import { languageApi } from '../../api/services/language';
import { useBookCreatorFields } from './useBookCreatorFields';
import { booksApi } from '../../api/services/books';
import { changeMultiSelectOptions } from '../../helpers/changeMultiSelectOptions';

const BooksNew: React.FC = () => {
  const { options: sourcesOptions } = useOptions('sources', () => sourceApi.getAllSources(1000, 1));
  const { options: subcategoryOptions } = useOptions('bookSubcategories', () =>
    bookSubcategoriesApi.getAllBookSubcategories(1000, 1)
  );
  const { options: languageOptions } = useOptions('languages', languageApi.getAllLanguages);
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const releaseDate = moment(new Date(data?.releaseDate)).format('MM/DD/YYYY');

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { book },
        } = await booksApi.getBookByUuid(uuid);

        setData(book);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Book"
        description="Create a book"
        validation={data ? bookUpdateValidation : bookValidation}
        fields={useBookCreatorFields({ sourcesOptions, subcategoryOptions, languageOptions })}
        submitFunction={data ? booksApi.updateBook : booksApi.createBook}
        initialData={
          data
            ? {
                ...data,
                sources: changeMultiSelectOptions(data?.sources),
                bookSubcategories: changeMultiSelectOptions(data?.bookSubcategories),
                releaseDate: new Date(releaseDate),
              }
            : null
        }
      />
    )
  );
};

export default BooksNew;
