export const useSwiperSliderCreatorFields = ({ categoryOptions, subcategoryOptions }) => [
  { name: 'isShown', label: 'Shown', type: 'checkbox', defaultValue: true },
  { name: 'image', label: 'Image', type: 'file' },
  {
    name: 'categoryUuid',
    label: 'Categories',
    type: 'singleSelect',
    options: [...categoryOptions],
  },
  {
    name: 'subcategoryUuid',
    label: 'Sub Categories',
    type: 'singleSelect',
    options: [...subcategoryOptions],
  },
  { name: 'order', label: 'Order', type: 'number' },
];
