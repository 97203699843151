import * as yup from 'yup';

export const SignInSchema = yup.object({
  email: yup.string().email().required().max(100, 'Email cannot exceed 100 characters.'),
  password: yup
    .string()
    .required()
    .min(8, 'Password must be at least 8 characters long')
    .max(20, 'Password must be at most 20 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one digit')
    .matches(/[\W_]/, 'Password must contain at least one special character'),
});
