import { useState, useEffect } from 'react';

const useOptions = (section, getAllData) => {
  const [sectionData, setSectionData] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getSectionData = async () => {
      try {
        const { data } = await getAllData();
        setSectionData(data?.[section]);
      } catch (error) {
        console.error(error);
      }
    };

    getSectionData().catch((error) => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    setOptions([]);
    sectionData?.forEach((item) => {
      setOptions((prevState) => [...prevState, { value: item?.uuid, label: item?.nameEn }]);
    });
  }, [sectionData]);

  return {
    options,
  };
};

export default useOptions;
