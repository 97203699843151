import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import useOptions from '../../hooks/useOptions';
import { positionStatusApi } from '../../api/services/positionStatus';
import { navigationApi } from '../../api/services/navigation';
import { positionStatusValidation } from './positionStatusValidation';
import { usePositionStatusCreatorFields } from './usePositionStatusCreatorFields';

const PositionStatusNew: React.FC = () => {
  const { options: navigationOptions } = useOptions('navigation', () =>
    navigationApi.getAllNavigations(1000, 1)
  );

  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { positionStatus },
        } = await positionStatusApi.getPositionStatusByUuid(uuid);

        setData(positionStatus);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Position status"
        description="Create a position status"
        validation={positionStatusValidation}
        fields={usePositionStatusCreatorFields({ navigationOptions })}
        submitFunction={
          data ? positionStatusApi.updatePositionStatus : positionStatusApi.createPositionStatus
        }
        initialData={data}
      />
    )
  );
};

export default PositionStatusNew;
