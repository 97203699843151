import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { pageContentEn } from '../../../constants/pageContent';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { userLanguageApi } from '../../../api/services/userLanguage';

export const useDeleteUserLanguage = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line consistent-return
  const mutationFunction = async (deletedUserLanguageUuid: string) => {
    try {
      const response = await userLanguageApi.deleteUserLanguage(deletedUserLanguageUuid);
      toast.success(response?.data?.message || pageContentEn.USER_LANGUAGE_DELETED_SUCCESSFULLY);
      return response;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const { mutateAsync: deleteUserLanguageMutate } = useMutation({
    mutationFn: mutationFunction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userLanguages'] });
    },
  });

  return { deleteUserLanguageMutate };
};
