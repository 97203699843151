import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormCreator } from '../../components';
import { userApi } from '../../api/services/userApi';
import { userValidation } from './userValidation';
import { useUserCreatorFields } from './useUserCreatorFields';

const CustomersNew: React.FC = () => {
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { user },
        } = await userApi.getUserByUuid(uuid);

        setData(user);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="User"
        description="Update a user"
        validation={userValidation}
        fields={useUserCreatorFields()}
        submitFunction={data ? userApi.updateUser : userApi.updateUser}
        initialData={data}
      />
    )
  );
};

export default CustomersNew;
