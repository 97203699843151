import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../@/components/ui/form';
import { Textarea } from '../../../@/components/ui/textarea';

const TextareaField = ({ form, elem, loading }) => (
  <div className="mb-5">
    {
      <div className="flex flex-col">
        <FormField
          control={form.control}
          name={elem.name}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{elem.label}</FormLabel>
              <FormControl>
                <Textarea disabled={loading} placeholder={elem.label} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    }
  </div>
);

export default TextareaField;
