import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { VscError } from 'react-icons/vsc';

import useAuth from '../../hooks/useAuth';
import { SignInSchema } from './SignInSchema';
import { Form } from '../../@/components/ui/form';
import { Button } from '../../@/components/ui/button';
import CheckboxField from '../../components/FormCreator/Fields/CheckboxField';
import FormTextField from '../../components/FormCreator/Fields/FormTextField';

import styles from './style.module.scss';

const SignIn = () => {
  const { signIn } = useAuth();
  const [signInError, setSignInError] = useState(null);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(SignInSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await signIn(data);
    } catch (error) {
      setSignInError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (signInError) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [signInError]);

  return (
    <>
      {!!signInError && (
        <div className={styles.response_error_message}>
          <VscError size={18} />
          {signInError}
        </div>
      )}
      <div className={styles.form_wrapper}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={styles.form}>
            <FormTextField elem={{ name: 'email', label: 'Email' }} form={form} loading={loading} />
            <FormTextField
              elem={{ name: 'password', label: 'Password' }}
              form={form}
              loading={loading}
              type="password"
            />
            <CheckboxField
              elem={{ name: 'remember', label: 'Remember me' }}
              form={form}
              loading={loading}
            />
            <Button disabled={loading} type="submit" className={`ml-auto`}>
              Sign-in
            </Button>
          </form>
        </Form>
      </div>
    </>
  );
};

export default SignIn;
