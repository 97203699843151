export const useCategoryCreatorFields = ({ navigationOptions }) => [
  { name: 'isShown', label: 'Shown', type: 'checkbox', defaultValue: true },
  { name: 'isDropdown', type: 'checkbox', label: 'Dropdown', defaultValue: true },
  { name: 'nameEn', label: 'Name english', type: 'text' },
  { name: 'nameRu', label: 'Name russian', type: 'text' },
  { name: 'descriptionEn', label: 'Description english', type: 'textarea' },
  { name: 'descriptionRu', label: 'Description russian', type: 'textarea' },
  {
    name: 'navigationUuid',
    label: 'Navigation',
    type: 'singleSelect',
    options: [...navigationOptions],
  },
  { name: 'order', label: 'Order', type: 'number' },
];
