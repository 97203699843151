import * as yup from 'yup';

const MAX_FILE_SIZE = 5120000; // 5MB

const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}

export const bookSubcategoryValidation = {
  image: yup
    .mixed()
    .required('Required')
    .test('is-valid-type', 'Not a valid image type', (value) =>
      isValidFileType(value && value[0]?.name?.toLowerCase(), 'image')
    )
    .test(
      'is-valid-size',
      'Max allowed size is 5MB',
      (value) => value && value?.[0]?.size <= MAX_FILE_SIZE
    ),
  nameEn: yup.string().required('Required'),
  nameRu: yup.string().required('Required'),
  descriptionEn: yup.string().required('Required'),
  descriptionRu: yup.string().required('Required'),
  isShown: yup.boolean().required('Required'),
  bookCategories: yup.array().of(
    yup.object({
      value: yup.string().required('Required'),
      label: yup.string().required('Required'),
    })
  ),
  order: yup.number().min(0),
};

export const bookSubcategoryUpdateValidation = {
  nameEn: yup.string().required('Required'),
  nameRu: yup.string().required('Required'),
  descriptionEn: yup.string().required('Required'),
  descriptionRu: yup.string().required('Required'),
  isShown: yup.boolean().required('Required'),
  bookCategories: yup.array().of(
    yup.object({
      value: yup.string().required('Required'),
      label: yup.string().required('Required'),
    })
  ),
  order: yup.number().min(0),
};
