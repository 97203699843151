import { Controller } from 'react-hook-form';

import { Label } from '../../../@/components/ui/label';

const FormFileField = ({ elem, control, errors, setValue, name, initialData }) => {
  const handleFileChange = (e) => {
    setValue(name, e.target.files);
  };

  return (
    <div className="mb-5">
      <div className="flex flex-col">
        <Label className="m-2">{elem.label}</Label>
        <Controller
          name={elem?.name}
          control={control}
          render={({ field }) => (
            <div className="flex">
              <input name={elem?.name} type="file" onChange={handleFileChange} />
              {initialData?.[elem?.name]}
            </div>
          )}
        />
      </div>
      {errors[elem?.name] && (
        <Label className="text-xs text-red-600 mt-1">
          {errors[elem?.name]?.message || 'Required'}
        </Label>
      )}
    </div>
  );
};

export default FormFileField;
