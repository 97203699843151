import { AxiosResponse } from 'axios';

import instance from '../axios';
import { userType, visitInformationType } from '../../../types/types';

interface getUserByUuidType {
  user: userType;
}

interface getUsersVisitsType {
  success: boolean;
  statusCode: number;
  visitInformation: visitInformationType;
}

interface allUsersType {
  users: userType[];
  totalCount: number;
}

interface userApiType {
  getUser: () => Promise<AxiosResponse<userType>>;
  getAllUsers: (
    pageSize?: number,
    currentPage?: number,
    searchValue?: string
  ) => Promise<AxiosResponse<allUsersType>>;
  getUserByUuid: (userUuid: string) => Promise<AxiosResponse<getUserByUuidType>>;
  getUsersDaysVisits: () => Promise<AxiosResponse<getUsersVisitsType>>;
  postUserUpdatedData: (data: any) => Promise<AxiosResponse<any>>;
  updateUser: (uuid: string, data: any) => Promise<AxiosResponse<any>>;
  deleteUser: (uuid: string) => Promise<AxiosResponse<any>>;
}

export const userApi: userApiType = {
  getUser: async () => instance.get(`v1/user/get-user`),
  getAllUsers: async (pageSize, currentPage, searchValue) =>
    instance.get(`v1/user/get-all`, { params: { pageSize, currentPage, searchValue } }),
  getUserByUuid: async (userUuid) => instance.get(`v1/user/get-by-uuid/${userUuid}`),
  postUserUpdatedData: async (data) => instance.post(`v1/user/update`, data),
  updateUser: async (uuid, data) => instance.post(`/v1/user/update/${uuid}`, data),
  deleteUser: async (uuid) => instance.delete(`/v1/user/delete/${uuid}`),
  getUsersDaysVisits: async () => instance.get(`/v1/info/getAllDaysVisits`),
};
