import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import SignIn from '../../pages/SignIn/SignIn';

const AuthProvider = ({ children }) => {
  let token;
  const navigate = useNavigate();

  const [authStatus, setAuthStatus] = useState('loading');

  if (typeof localStorage !== 'undefined' && typeof sessionStorage !== 'undefined') {
    token = localStorage?.getItem('sb_admin_token') || sessionStorage?.getItem('sb_admin_token');
  }

  useEffect(() => {
    if (!token) {
      setAuthStatus('notAuthorized');
    } else {
      setAuthStatus('authorized');
    }
  }, []);

  useEffect(() => {
    if (authStatus === 'notAuthorized') {
      navigate('/sign-in');
    }
  }, [authStatus]);

  return (
    <>
      {authStatus === 'loading' && <Loading />}
      {authStatus === 'notAuthorized' && <SignIn />}
      {authStatus === 'authorized' && children}
    </>
  );
};

export default AuthProvider;
