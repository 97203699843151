import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { languageType } from '../../../types/types';

type LanguageStateType = {
  languages: languageType[];
};

const initialState: LanguageStateType = {
  languages: [],
};

export const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setLanguages: (state: LanguageStateType, action: PayloadAction<languageType[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.languages = action.payload;
    },
  },
});

export const { setLanguages } = languagesSlice.actions;
export default languagesSlice.reducer;
