import { ColumnDef } from '@tanstack/react-table';

import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  nameEn: string;
  nameRu: string;
  image: string;
  isShown: string;
  order: string;
};

export const useSubcategoryTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'order', header: 'Order' },
  { accessorKey: 'image', header: 'Image' },
  { accessorKey: 'nameEn', header: 'Name english' },
  { accessorKey: 'nameRu', header: 'Name russian' },
  { accessorKey: 'isShown', header: 'Is shown' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'subcategory'} />,
  },
];
