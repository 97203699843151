import { createSlice } from '@reduxjs/toolkit';
import { userType } from '../../../types/types';

type UserStateType = {
  user: userType;
};

const initialState = {
  user: {},
} as UserStateType;

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: UserStateType, action) => {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload;
    },
    clearUser: (state: UserStateType) => {
      // eslint-disable-next-line no-param-reassign,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      state.user = {};
    },
    updateCourseFavoriteList: (state: UserStateType, action) => {
      // eslint-disable-next-line no-param-reassign
      state.user.courseFavoriteList = action.payload;
    },
  },
});

export const { setUser, clearUser, updateCourseFavoriteList } = user.actions;
export default user.reducer;
