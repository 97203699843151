import React, { useState } from 'react';
import { Page } from '../index';
import { ColumnType, navigationTableColumns } from './navigationTableColumns';
import { useGetNavigations } from '../../hooks/ReactQuery/Navigation/useGetNavigations';
import Loading from '../../components/Loading/Loading';
import useQueryParams from '../../helpers/useQueryParams';

const Navigation = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetNavigations(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] = data?.navigation?.map((elem: any) => ({
    id: elem?.uuid,
    nameEn: elem?.nameEn,
    nameRu: elem?.nameRu,
    isDropdown: elem.isDropdown,
    isShown: elem.isShown,
    order: elem.order,
  }));

  return (
    <Page
      title="Navigation"
      description="Navigation info"
      columns={navigationTableColumns}
      formattedData={formattedData}
      searchKey="nameEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default Navigation;
