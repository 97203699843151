import React from 'react';
import moment from 'moment';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../@/components/ui/form';
import { Popover, PopoverTrigger } from '../../../@/components/ui/popover';
import { Input } from '../../../@/components/ui/input';

const ReactDatepicker = ({ elem, form, loading }) => (
  <div className="mb-5">
    <div className="flex flex-col">
      <FormField
        control={form.control}
        name={elem.name}
        render={({ field }) => (
          <FormItem className="flex flex-col">
            <FormLabel>{elem.label}</FormLabel>
            <FormControl>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Input
                      type="date"
                      value={field.value ? moment(field.value).format('yyyy-MM-DD') : field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                </PopoverTrigger>
              </Popover>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  </div>
);

export default ReactDatepicker;
