import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isPremium: boolean;
};

export const useUserTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'firstName', header: 'First name' },
  { accessorKey: 'lastName', header: 'Last name' },
  { accessorKey: 'email', header: 'Email' },
  { accessorKey: 'isPremium', header: 'Premium' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'user'} />,
  },
];
